@keyframes overlayTextAnimation {
  from {
    opacity: 0;
    transform: translate(0, 50px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes overlayBackgroundAnimation {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(0, 100%); } }

@keyframes overlayH2Animation {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes overlayAnimation {
  from {
    height: 100%; }
  to {
    height: 0%; } }

html {
  font-size: 50%;
  height: 100%; }

body {
  font-family: 'Circular Std Book';
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  background: #fff; }
  body * {
    box-sizing: border-box; }
  @media only screen and (max-width: 2040px) {
    body {
      font-size: 32px; } }
  @media only screen and (max-width: 1440px) {
    body {
      font-size: 24px; } }
  @media only screen and (max-width: 600px) {
    body {
      font-size: 18px; } }
  @media only screen and (max-width: 400px) {
    body {
      font-size: 14px; } }

strong {
  font-family: 'Circular Std Bold'; }

a {
  text-decoration: underline;
  color: #000; }
  a:hover {
    text-decoration: none; }

.background {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: -2; }

header {
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  header h1.logo a {
    font-family: 'Circular Std Black';
    color: #000;
    text-decoration: none; }
  header a.av {
    font-size: 12px; }

section.welcome {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  section.welcome .hello {
    font-family: 'Circular Std Bold';
    font-size: 5rem;
    line-height: 7.5rem; }
  section.welcome p {
    max-width: 150rem;
    text-align: center; }
  section.welcome .description {
    margin: 8rem 0; }
  section.welcome .email-link {
    font-size: 4rem;
    font-family: 'Circular Std Bold';
    color: #000;
    text-decoration: none;
    position: relative;
    transition: all 0.15s ease-in-out;
    padding: 0 0.5rem; }
    section.welcome .email-link:after {
      width: 100%;
      height: 0.5rem;
      background: #000;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      border-radius: 0.5rem;
      z-index: -1;
      box-shadow: rgba(45, 45, 45, 0) 0 1px 1px, rgba(49, 49, 49, 0) 0px 2px 2px, rgba(42, 42, 42, 0) 0px 4px 4px, rgba(32, 32, 32, 0) 0px 8px 8px; }
    section.welcome .email-link:hover {
      color: #fff; }
  section.welcome .email-link:hover:after {
    height: 100%;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: rgba(45, 45, 45, 0.2) 0 1px 1px, rgba(49, 49, 49, 0.2) 0px 2px 2px, rgba(42, 42, 42, 0.2) 0px 4px 4px, rgba(32, 32, 32, 0.2) 0px 8px 8px; }
  section.welcome .email-link:active:after {
    transition: all 0.1s ease-in-out;
    box-shadow: rgba(45, 45, 45, 0) 0 1px 1px, rgba(49, 49, 49, 0) 0px 2px 2px, rgba(42, 42, 42, 0) 0px 4px 4px, rgba(32, 32, 32, 0) 0px 8px 8px; }
  section.welcome ul {
    margin-top: 8rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }
    section.welcome ul li a {
      margin: 0 1rem;
      text-decoration: none;
      color: #000; }

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: overlayAnimation 0.01s;
  animation-fill-mode: forwards;
  animation-delay: 4300ms; }
  .overlay h2 {
    color: #fff;
    font-size: 3rem;
    font-family: 'Circular Std Bold';
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    animation: overlayH2Animation 1s;
    animation-fill-mode: forwards;
    animation-delay: 3000ms; }
    @media only screen and (max-width: 530px) {
      .overlay h2 {
        font-size: 2rem; } }
    @media only screen and (max-width: 320px) {
      .overlay h2 {
        font-size: 1.5rem; } }
    .overlay h2 span {
      opacity: 0;
      animation: overlayTextAnimation 1s;
      animation-fill-mode: forwards;
      margin: 0 0.75rem; }
      .overlay h2 span:nth-child(2) {
        animation-delay: 1000ms; }
      .overlay h2 span:nth-child(3) {
        animation-delay: 2000ms; }
  .overlay__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .overlay__bg div {
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      animation: overlayBackgroundAnimation 1s;
      animation-fill-mode: forwards;
      animation-delay: 3000ms; }
      .overlay__bg div:nth-child(2) {
        left: 25%;
        animation-delay: 3100ms; }
      .overlay__bg div:nth-child(3) {
        left: 50%;
        animation-delay: 3200ms; }
      .overlay__bg div:nth-child(4) {
        left: 75%;
        animation-delay: 3300ms; }
